import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="hyperlinks" mdxType="Subpage">
      <h3>{`Hyperlinks`}</h3>
      <Callout number="3.1" term="Hyperlinks" color="alternate" mdxType="Callout">
        <p>{`Hyperlinks are created with the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<a>`}</code>{` tag. This usually wraps some HTML content such as text or an image that acts as the anchor for the link.`}</p>
      </Callout>
      <p>{`Markup that gives structure to content provides a foundation for building web pages. But what makes web documents different from other documents is that web documents can include hyperlinks that connect them to each other.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read pp. 74-80 and 85-90 in Ch.4 of Duckett`}</p>
        <small>to learn some of the basics about using the <code>&lt;a&gt;</code> tag and its attributes.</small>
      </Callout>
      <p>{`Chapter 4 covers the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{` tag and how to link to websites and pages within your own website. To provide you additional practice with these concepts, let’s start by looking more at how to add `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{` tags and configure them to point to a website on the Internet.`}</p>
      <p>{`First of all, note that when we create hyperlinks we tend to call them just "links," and they are created with the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{` tag. This tag technically represents a "hyperlink anchor," since such a link is the launching point or anchor from which a user clicks to connect to another site, page, or document. However, there are a few other tags that could be confused with the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{` tag.`}</p>
      <p>{`Compare the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{` tag to two other tags that you might mistake as hyperlinks:`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Tag`}</th>
            <th parentName="tr" {...{
              "align": "left"
            }}>{`What it stands for`}</th>
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Purpose`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`<a>`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Hyperlink anchor`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`This creates actual hyperlink anchors, commonly referred to as "links." Usually when we talk casually about "links" we really mean hyperlinks, or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`<a>`}</code>{` tags.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`<link/>`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Script link`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`This is placed in the `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`<head>`}</code>{` element in order to import resources such as CSS style sheets. This is NOT a hyperlink or used to create links in the `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`<body>`}</code>{` tag even though it seems obviously named as a "link." We will call these "Script Links" or "head link tags."`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`<li>`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`List item`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`This marks items in a list. It is NOT at all related to a link, even though it could appear to be an abbreviation for “link.” They represent “list item.” And for the record, we usually pronounce this tag as an an acronym "l-i" (or "el-eye") not "lee."`}</td>
          </tr>
        </tbody>
      </table>
      <p>{`So make sure you understand that when you want to create a link in your content you use the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{` tag, not
`}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<link/>`}</code>{` or `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<li>`}</code>{`. These serve other purposes.
Observe each of these tags used correctly in the following code snippet:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`head`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Testing some tags`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`link`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`type`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`text/css`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`rel`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`stylesheet`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`styles.css`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`head`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`body`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ol`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`This is item 1 in a list.`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`This is item 2 in a list.`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ol`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`http://cedarville.edu`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`This is a hyperlink
           to the Cedarville homepage.`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`   
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`body`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    </Subpage>
    <Subpage slug="paths" mdxType="Subpage">
      <h3>{`Pointing to Files with Paths`}</h3>
      <Callout number="3.2" term="'href' and Paths" color="alternate" mdxType="Callout">
        <p>{`The `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`href`}</code>{` attribute must be set in order for a hyperlink to point to the desired file or URL.`}</p>
      </Callout>
      <p>{`Now that you grasp the difference between `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{` tags and other tags newbies often mistake for hyperlinks, let’s talk about how to configure them to point to websites using the href attribute. This stands for hyperlink reference and it indicates which website, page, or file our `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{` tag should point to. The attribute value for the href attribute is referred to as a path as it indicates the address from here to some location on the Internet. So in this example...`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`http://www.example.com`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Click me`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span></span></pre></div>
      <p>{`...when the user clicks the link “Click me” they will be taken to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`http://www.example.com`}</code>{`. As discussed in chapter 4 from Duckett, this is an absolute URL or absolute path, pointing outside of whatever site this link is actually in, to the default web page for the site `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`http://www.example.com`}</code>{`. Whenever we point to a website other than our own, make sure that...`}</p>
      <ul>
        <li parentName="ul">{`the path always begins with `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`http://`}</code></li>
        <li parentName="ul">{`the domain name and any specific page is spelled correctly`}</li>
      </ul>
      <p>{`A helpful tip to ensure you get the right path is to actually visit the desired website in a browser, and then copy and paste the URL into your href attribute. Then just ensure that it starts with `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`http://`}</code>{`.`}</p>
      <Callout number="3.3" term="Relative paths..." color="alternate" mdxType="Callout">
        <p>{`...point from one file to another file within the same computer directory, be it further into a folder structure, or a few folders up from the current file.`}</p>
      </Callout>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read pp. 81-84 and 91-92 in Ch. 4 of Duckett`}</p>
        <small>to learn more about linking to files using paths and to see a summary of this chapter on links.</small>
      </Callout>
      <p>{`Duckett goes on in chapter 4 to describe relative URLs or relative paths which allow us to point to files within our own site. Examine the chart that follows for more details and examples.`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`For files that reside in the same folder as the one from which we are pointing, we simply enter the file name and extension as the link path.`}</p>
          <p parentName="li">{`  Here, the path from `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`index.html`}</code>{` to `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`styles.css`}</code>{` is: `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`styles.css`}</code></p>
          <p parentName="li">{`  `}<img parentName="p" {...{
              "src": "/images/path-direct.png",
              "alt": null
            }}></img></p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`If the file is deeper inside a folder and that folder is inside the same folder as the one from which we are pointing, we start by naming that folder, then entering a `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`/`}</code>{` forward slash, then listing the name of the file in that folder.`}</p>
          <p parentName="li">{`  Here, the path from `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`index.html`}</code>{` to `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`list.html`}</code>{` is: `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`products/list.html`}</code></p>
          <p parentName="li">{`  `}<img parentName="p" {...{
              "src": "/images/path-down.png",
              "alt": null
            }}></img></p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`If the file is several folders in, just continue to list each progressive folder.`}</p>
          <p parentName="li">{`  Here, the path from `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`index.html`}</code>{` to `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`clogs.html`}</code>{` is: `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`products/men/shoes/clogs.html`}</code></p>
          <p parentName="li">{`  `}<img parentName="p" {...{
              "src": "/images/path-multi-down.png",
              "alt": null
            }}></img></p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`If the file is in a folder that is outside of the current file’s folder, start with `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`../`}</code>{` which moves out one folder. Continue to move out successive layers with more `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`../`}</code>{` if needed.`}</p>
          <p parentName="li">{`  Here, the path from index.html to list.html is: `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`../list.html`}</code></p>
          <p parentName="li">{`  `}<img parentName="p" {...{
              "src": "/images/path-up.png",
              "alt": null
            }}></img></p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Finally, enter the folder name to begin moving back inward again. Continue adding folders until you reach the desired folder and then enter the desired file name as in the first point above.`}</p>
          <p parentName="li">{`  Here, the path from the `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`index.html`}</code>{` in the `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`men`}</code>{` folder to `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`catalogue.pdf`}</code>{` is: `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`../../docs/catalogue.pdf.	`}</code></p>
          <p parentName="li">{`  `}<img parentName="p" {...{
              "src": "/images/path-up-and-down.png",
              "alt": null
            }}></img></p>
        </li>
      </ul>
      <p>{`See paths explained in this video:`}</p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/jsh1m-34ieQ?list=PLuOViGmL7TfXlFl74Hf84NwSQPR_LDR0d" frameborder="0" allowfullscreen></iframe>
      <Callout number="3.4" term="'id' references and placeholder links" color="alternate" mdxType="Callout">
        <p>{`The `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`#`}</code>{` (pound sign) can be used in `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`href`}</code>{` attributes as a placeholder or to point to another element in the document using its `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`id`}</code>{` attribute.`}</p>
      </Callout>
      <p>{`We can also link within a document by pointing to elements that have an id value. Imagine you have document that has an `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<h1>`}</code>{` at the very top with an id of brand. Also imagine it is a very long page. In order to help users quickly get back to the top of the page, you could put a hyperlink at the bottom like this:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#brand`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Back to top`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span></span></pre></div>
      <p>{`Notice that the href attribute here simply points to the element that has an id of brand. So when a user clicks this link, they'll be taken right back up to the top of the document where that `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<h1>`}</code>{` is located. You can use this principle to link to any element in a document as long as it has an id set.`}</p>
      <p>{`Note as well that if you want to enter a link to a file but you don't know where the file is yet or you haven't created it yet, the best thing to do is provide just a # as the href as a placeholder. The result is that the link appears in the browser, but when the user clicks on it, they're not taken anywhere. Just don't forget to come back later and provide an actual path!`}</p>
    </Subpage>
    <Subpage slug="image-markup" mdxType="Subpage">
      <h3>{`Image Markup`}</h3>
      <h4>{`Preparing Images for the web`}</h4>
      <p>{`Watch this short introduction to preparing images to use in a website.`}</p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/BQ3xQIR-j1c" frameborder="0" allowfullscreen></iframe>
      <h4>{`The `}<code parentName="h4" {...{
          "className": "language-text"
        }}>{`<img/>`}</code>{` tag`}</h4>
      <Callout number="3.5" term="Images" color="alternate" mdxType="Callout">
        <p>{`Images are created with the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<img/>`}</code>{` tag and the desired image is indicating using the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`src`}</code>{` attribute.`}</p>
      </Callout>
      <p>{`Our final area of focus is in creating markup that allows us to incorporate image files into our web pages.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read Ch. 5 from Duckett.`}</p>
      </Callout>
      <p>{`So far we've talked about paths in the context of adding hyperlinks from one document to the next. Chapter 5 from Duckett elaborates on how to use paths and the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<img/>`}</code>{` tag in order to insert images into our markup. The `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<img/>`}</code>{` tag is one of those tricky self-closing tags; so we DO NOT do this:`}</p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`<img></img>`}</code></p>
      <p>{`Instead we do this:`}</p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`<img/>`}</code></p>
      <p>{`But just like this, we have no idea which image is to be inserted. So we use the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`src`}</code>{` attribute to provide the path to the desired image:`}</p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`<img src="images/logo.png" />`}</code></p>
      <p>{`This assumes that in the current folder there is another folder called `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`images`}</code>{` and in it is an image, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`logo.png`}</code>{`.`}</p>
      <h4>{`The `}<code parentName="h4" {...{
          "className": "language-text"
        }}>{`alt`}</code>{` Attribute`}</h4>
      <Callout number="3.6" term="The 'alt' attribute..." color="alternate" mdxType="Callout">
        <p>{`...is used on images particularly to provide plain text explanations of the image or text content in the image.`}</p>
      </Callout>
      <p>{`While Duckett discusses `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<img/>`}</code>{` elements and their attributes in more detail, one you should note in particular is the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`alt`}</code>{` attribute. This allows us to provide alternate text for an image. There are several instances where this is necessary:`}</p>
      <ul>
        <li parentName="ul">{`Provide a caption for an image if the image’s surrounding content does not provide this. This also allows search engines to discover content of images in case this is relevant for your users.`}</li>
        <li parentName="ul">{`Provide fallback text for an image that contains text so that if the image is not available, the user will see text rather than just a broken image icon.`}</li>
        <li parentName="ul">{`It is a best practice to provide the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`alt`}</code>{` attribute for every image you insert. The only common exceptions are when the image is purely decorative (it adds no content to the page, but adds a visual embellishment) or when surrounding text provides a clear enough caption for the image.`}</li>
      </ul>
      <h4>{`Content in Images`}</h4>
      <Callout number="3.7" term="Images with text content" color="alternate" mdxType="Callout">
        <p>{`When choosing which images to include in your markup versus apply using CSS, distinguish between images that contribute content vs. embellishment. Ensure that images with significant text content are marked semantically and be sure to record the text content of the image using the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`alt`}</code>{` attribute.`}</p>
      </Callout>
      <p>{`We can do a lot today with CSS, especially when it comes to creating graphical layouts. We'll learn more about this later in the course. But now, as you learn how to include images in your content, it is important to consider what images you should include in your markup versus leave out with the intent of applying with with CSS later.`}</p>
      <p>{`As you study a document to mark up and encounter images, ask yourself whether the image contributes to the content or embellishes through decoration. Leave out any images that might be consider decoration or could be part of the background (textures, patterns, large background images, etc.).`}</p>
      <p>{`Be on the watch specifically for text content in images. As mentioned earlier, the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<img/>`}</code>{` tag allows text content to be recorded in the alt attribute. But why would text be in an image to begin with? Sometimes web designers prefer to create heading text as images so as to have more control of the font and related properties. When that information is frozen as pixels in an image (or line art in an SVG graphic) the the designer can maintain control of kerning, color, and font properties. This is not recommended today since we have access to many more fonts that we used to.`}</p>
      <p>{`One very common set of content to include as an image is the company's logo or wordmark, since this is often desired to be 100% consistent for branding reasons. This will also often be the worthy name of the site, so it might be best marked with an `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<h1>`}</code>{` tag. The `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<img/>`}</code>{` tag can go inside the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<h1>`}</code>{`. Then be sure to provide the text content of the logo in the alt attribute of the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<img/>`}</code>{` tag. Consider this markup for the Cedarville University website's masthead as a pattern that is used in many sites:`}</p>
      <p><img parentName="p" {...{
          "src": "/images/cu-masthead.png",
          "alt": null
        }}></img></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`header`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`id`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`site-masthead`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/logo.png`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{`
             `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`alt`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`Cedarville University`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`nav`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`id`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`nav-main`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`nav`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`header`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <h4>{`Other Image Attributes and Markup`}</h4>
      <p>{`Here are a few more comments on content from chapter 5:`}</p>
      <ul>
        <li parentName="ul">{`Be sure to check out the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<figure>`}</code>{` and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<figcaption>`}</code>{` elements Duckett discusses near the end of chapter 5 for a modern example of how to provide captions for images.`}</li>
        <li parentName="ul">{`I consider it helpful to leave off any `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`width`}</code>{` or `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`height`}</code>{` attributes on images. This allows you to instead control its dimensions with CSS, making it flexible to scale and resize on different devices. At the time this module was written the field was in a transitional period regarding best practice for flexible images.`}</li>
      </ul>
      <h4>{`Placeholder Images`}</h4>
      <Callout number="3.8" term="Placeholders for images" color="alternate" mdxType="Callout">
        <p>{`If you don't have an actual image yet, you can provide a `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`src`}</code>{` path of `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`#`}</code>{` for a placeholder or make use of the service provided at `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`http://placehold.it`}</code>{`.`}</p>
      </Callout>
      <p>{`Sometimes we want to add an image placeholder in our markup because either we don’t know where the image we want is, or we simply haven’t generated it yet. In such cases, there are two approaches you can use. The simplest is to use a hash for the source instead of a valid path:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`alt`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`Candid shot of the kids at the pool`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span></span></pre></div>
      <p>{`If we look at this in a browser we’ll see an icon representing a broken link, but that is OK; we’ll be placing the actual image path in later. We’d certainly want to replace this `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`#`}</code>{` before we send anything to a client.`}</p>
      <p>{`Some prefer a more intentional look, so another option is to use a service such as placehold.it like this:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`http://placehold.it/300x200`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{`
     `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`alt`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`Candid shot of the kids at the pool`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`In this case, we’re linking to a dynamic image from another website that shows a grey box with the text `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`300x200`}</code>{` and sized by default at 300 pixels wide and 200 pixels high. Learn more about this service and how to use it at `}<a parentName="p" {...{
          "href": "http://placehold.it/"
        }}>{`placehold.it »`}</a>{`.`}</p>
      <h4>{`Choosing Image Types`}</h4>
      <Callout number="3.9" term="Which Images Types to Use" color="alternate" mdxType="Callout">
        <p>{`JPEG and PNG are great image types for the web, depending on whether or not you need transparency in an image. GIF is another good option when an image has only a few colors.`}</p>
      </Callout>
      <p>{`Later in the course we'll talk more about the process by which we export images or "slices" from a design comp in Figma which we call "slicing." As you learned about several image formats in the Duckett text, let me suggest a quick process you can walk through when choosing image types:`}</p>
      <ul>
        <li parentName="ul">{`Does the image require transparency?`}<ul parentName="li">
            <li parentName="ul">{`If yes, does the image require either smooth colors such as in photography or gradients, or smooth transparency where some pixels are semitransparent?`}<ul parentName="li">
                <li parentName="ul">{`If yes to any of these, choose the PNG format.`}</li>
                <li parentName="ul">{`If no to all, choose the GIF format.`}</li>
              </ul></li>
            <li parentName="ul">{`If no, does the image require smooth colors such as in photography or gradients?`}<ul parentName="li">
                <li parentName="ul">{`If yes use either PNG or JPEG.`}</li>
                <li parentName="ul">{`If no consider GIF.`}</li>
              </ul></li>
          </ul></li>
      </ul>
      <p>{`Bottom line: I typically use either PNG or JPEG depending on whether transparency is needed. Only when an image has just a few colors will I opt to use a GIF.`}</p>
    </Subpage>
    <Subpage slug="planning-research-discovery" mdxType="Subpage">
      <h3>{`Website Planning, Research, and Discovery`}</h3>
      <p>{`As part of our course's purpose is to discuss contemporary practices in web design, we'll use Brian Miller's fantastic text, `}<em parentName="p">{`Above the Fold`}</em>{` as a primary source. We begin our in-depth study with an excerpt from Chapter 1. In this chapter, Miller lays out the overall process we go through to plan a website. We'll read just the section related to understanding a given website project.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read pp. 22–29 in Ch. 1 of Miller.`}</p>
      </Callout>
      <p>{`Let's leave this chapter for now in order to look more closely at what can go into understanding a website's needs or requirements from the user's standpoint.`}</p>
    </Subpage>
    <Subpage slug="elements-of-usability" mdxType="Subpage">
      <h3>{`Elements of Usability`}</h3>
      <p>{`A major initiative in contemporary web design is to focus on the user's needs and represent them in any business discussion. This often takes a concerted effort on our part, because, ironically, business owners are often thinking about their own needs, preferences, or interests for their company's website. Chapter 2 from Miller provides an excellent overview of the study of usability. When we plan in this fashion, we can think beyond the day-to-day grind of building sites and instead show a care for the actual experience users have when visiting a site.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read Ch. 2 from Miller.`}</p>
      </Callout>
      <p>{`We benefit greatly from determining the business goals for a website. We benefit even more from understanding how those goals line up with actual user needs.`}</p>
      <p>{`Think back to the deliverables covered in your reading from Chapter 1. SWOT Analysis can bring both of these elements together, reflecting those strengths, weaknesses, opportunities, and threats from both the business and the user's perspective. A Creative Brief is a great way to outline your understanding and expectations for a project to ensure you and your client are on the same page before starting.`}</p>
    </Subpage>
    <Subpage slug="study-tools" mdxType="Subpage">
      <h3>{`Study Tools`}</h3>
      <p>{`Ensure that you can do the following:`}</p>
      <ul>
        <li parentName="ul">{`Create valid relative paths`}</li>
        <li parentName="ul">{`Add placeholder images`}</li>
        <li parentName="ul">{`Add images to content with correct paths`}</li>
        <li parentName="ul">{`User `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`alt`}</code>{` attributes correctly`}</li>
      </ul>
      <p>{`Review the key takeaways from this module. Memorize each of the following tags or attributes and understand what they are used for:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<a>`}</code>{` and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`href`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<img/>`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`src`}</code>{`, and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`alt`}</code></li>
      </ul>
      <p>{`Prepare answers to the following questions as you study for the exam covering this lesson:`}</p>
      <ul>
        <li parentName="ul">{`Describe the nature and purpose of SWOT Analysis.`}</li>
        <li parentName="ul">{`Describe the nature and purpose of a Creative Brief in the context of web design.`}</li>
        <li parentName="ul">{`Miller outlines seven broad factors that usability professionals consider. List and describe them in your own words.`}</li>
        <li parentName="ul">{`What is persistent navigation and what are some things Miller describes that we can do to make them clear and usable?`}</li>
        <li parentName="ul">{`What purpose do breadcrumbs navigation play in usability?`}</li>
        <li parentName="ul">{`What are some principles Miller describes for making buttons and links usable?`}</li>
        <li parentName="ul">{`What factors are involved in making site search features usable?`}</li>
        <li parentName="ul">{`How do form submissions, error messages, and the use of surprise and delight relate to each other in relation to user experience?`}</li>
      </ul>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      